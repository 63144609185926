.mobile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: black;
  height: 170px;
  width: 80px;
  overflow: hidden;
  padding: 4px;
  border-radius: 5px;
}
.mobile-square {
  width: 40%;
  height: 2px;
  background-color: white;
}
.mobile-img {
  height: 80%;
  flex: 1;
  margin: 4px 0 2px 0;
  overflow: hidden;
}
.mobile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.circle-m {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}
@media (max-width: 500px) {
  .mobile {
    position: absolute;
    right: 5%;
    bottom: 5%;
  }
}
