.navbar {
  position: relative;
  display: flex;
  justify-content: center;
}

.navbar > img {
  cursor: pointer;
  position: absolute;
  display: none;
  top: 2vh;
  left: 2%;
  width: 1.5rem;
  z-index: 2;
}

nav {
  position: absolute;
  z-index: 10;
  width: 100vw;
  max-width: 1800px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
}

nav a {
  color: #6e717a !important;
}

nav > img {
  display: none;
  position: absolute;
  right: 3%;
  top: 2vh;
  cursor: pointer;
  width: 1.5rem;
}

nav h1 {
  font-family: "Cinzel", serif;
  letter-spacing: 1px;
  color: var(--color4);
  font-size: 2rem;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5;
}
nav ul li {
  padding: 1rem 1.5rem;
}

nav ul li *:not(.active):hover {
  color: var(--color3) !important;
}

.active {
  border-top: 2px solid var(--color3);
  background: linear-gradient(
    to bottom,
    rgba(120, 132, 219, 0.2),
    rgba(120, 132, 219, 0.1),
    rgba(21, 26, 39, 0.1)
  );
}
.active * {
  color: var(--color3) !important;
}

@media (max-width: 1000px) {
  nav ul,
  nav {
    flex: none;
    justify-content: space-evenly;
  }
}
@media (max-width: 850px) {
  nav {
    background-color: black;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    overflow: hidden;
  }
  nav h1 {
    padding-top: 3vh;
  }
  nav > img {
    display: block;
  }
  nav ul {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 1;
  }
  nav ul li {
    width: 100%;
    text-align: center;
    margin-left: 1rem;
    padding: 1rem 0.5rem;
  }
  .active {
    border: none;
    border-left: 5px solid var(--color3);
    background: linear-gradient(
      to right,
      rgba(120, 132, 219, 0.2),
      rgba(120, 132, 219, 0.1),
      rgba(21, 26, 39, 0.1)
    );
  }
  .navbar > img {
    display: block;
  }
  .open {
    clip-path: circle(150% at 0% 0%);
    -webkit-clip-path: circle(150% at 0% 0%);
  }
  .close {
    clip-path: circle(0% at 0% 0%);
    -webkit-clip-path: circle(0% at 0% 0%);
  }
}
