.home {
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.home h1 {
  font-size: 4rem;
  letter-spacing: 1px;
  font-weight: 300;
  z-index: 2;
}
.home .name {
  font-family: "Cinzel", serif;
  color: var(--color4);
  font-weight: 800;
  font-size: 6rem;
  margin-left: 1rem;
  z-index: 2;
}
.home .title {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 18vh;
  overflow: hidden;
  z-index: 2;
}
.home h3 {
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5;
  font-size: 3rem;
  margin-bottom: 2.5rem;
  border-bottom: 2px solid var(--color4);
  z-index: 2;
}
.home p {
  letter-spacing: 1px;
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: center;
  z-index: 2;
}
.home .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  margin: 2rem 0;
  height: 10vh;
  overflow: hidden;
  z-index: 2;
}

.circle-1 {
  width: 300px;
  height: 300px;
  background-image: linear-gradient(
    to right top,
    #ffe93b,
    #bcf877,
    #86fdb5,
    #74fbe6,
    #95f3ff
  );
  opacity: 0.2;
  cursor: grab;
  top: -150px;
  left: -150px;
}
.circle-2 {
  background-color: #cf00fa;
  height: 10px;
  width: 10px;
  box-shadow: 0 0px 5px 10px #cf00fa;
  opacity: 0.5;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  left: 20%;
  bottom: 10%;
}
.circle-3 {
  background-color: #12ff20;
  height: 10px;
  width: 10px;
  box-shadow: 0 0px 5px 10px #12ff20;
  opacity: 0.5;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  right: 10%;
  top: 10%;
}

.triangle-1 {
  opacity: 0.5;
  transform: rotate(135deg);
  right: 20%;
  bottom: 10%;
}
.triangle-2 {
  position: relative;
  margin: 1rem 2rem 0 0;
  transform: rotate(90deg);
  border-bottom: 40px solid var(--color4);
}
.triangle-3 {
  transform: rotate(240deg);
  border-bottom: 10px solid rgb(0, 255, 255);
  left: 15%;
  top: 40%;
  opacity: 0.5;
  box-shadow: 0 0px 5px 10px rgb(0, 255, 255);
  background-color: rgb(0, 255, 255);
}
.triangle-4 {
  transform: rotate(310deg);
  border-bottom: 10px solid rgb(68, 0, 85);
  right: 15%;
  bottom: 40%;
  opacity: 0.5;
  box-shadow: 0 0px 5px 10px rgb(68, 0, 85);
  background-color: rgb(68, 0, 85);
}
.triangle-5 {
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 160px solid #21d910;
  left: -60px;
  bottom: -2%;
  opacity: 0.1;
  cursor: grab;
}
@media (max-width: 900px) {
  .home {
    padding: 0.5rem;
  }
  .home h1 {
    font-size: 2.5rem;
  }
  .home .name {
    font-size: 4rem;
  }
  .home h3 {
    font-size: 2rem;
  }
}
@media (max-width: 650px) {
  .home h1 {
    font-size: 2rem;
  }
  .home .name {
    font-size: 3rem;
  }
  .home h3 {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
  }
  .home p {
    font-size: 1rem;

    letter-spacing: 0;
  }
  .circle-1 {
    width: 150px !important;
    height: 150px !important;
    top: -10% !important;
    left: -10% !important;
  }
}
@media (max-width: 500px) {
  .triangle-2 {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid var(--color4);
    margin: 0 1rem 0 0;
  }
}
@media (max-width: 450px) {
  .triangle-2 {
    display: none;
  }
  .home .buttons {
    flex-direction: column;
    height: 15vh;
  }
}
@media (max-width: 350px) {
  .home h1 {
    font-size: 1.5rem;
  }
  .home .title {
    height: 18vh;
  }
  .home .name {
    font-size: 2rem;
  }
  .home h3 {
    font-size: 1rem;
    text-align: center;
    margin-top: 0.3rem;
  }
  .home p {
    font-size: 0.8rem;
  }
}
