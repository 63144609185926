.about {
  min-height: 100vh;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-title {
  max-height: 18vh;
  overflow: hidden;
  margin: 4vh 0 2vh 0;
}
.about h2 {
  font-family: "Cinzel", serif;
  color: var(--color4);
  font-size: 3rem;
  letter-spacing: 1px;
}
.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 530px;
}

.about-img-container {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
.about-img-container img {
  width: 100%;
  height: 100%;
  background-color: #6e717a;
  opacity: 0.8;
}
.about-text {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  background-color: #eb7c5b;
  overflow: hidden;
}
.about-text ul {
  list-style-type: circle;
  padding: 0.5rem 0.8rem;
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
}
.about-text ul li {
  color: white;
  line-height: 1.5;
  font-size: 1.1rem;
  letter-spacing: 1px;
  text-align: left;
  margin: 0.5rem;
  padding-left: 0.4rem;
}
@media (max-width: 1000px) {
  .about {
    width: 100vw;
  }
}
@media (max-width: 740px) {
  .about {
    width: 96vw;
  }
  .about-text ul li {
    line-height: 1.3;
    font-size: 1rem;

    margin: 0.5rem;
  }
  .about-title {
    margin-top: 8vh;
  }
  .about-title h2 {
    font-size: 2.5rem;
    text-align: center;
  }
  .about-container {
    flex-direction: column;
    height: fit-content;
    margin-bottom: 4vh;
  }
  .about-img-container {
    width: 90%;
  }
  .about-text {
    width: 90%;
  }
}
@media (max-width: 450px) {
  .about-text ul li {
    font-size: 0.8rem;
  }
  .about-title {
    margin: 10vh 0 2vh 0;
  }
  .about-title h2 {
    font-size: 2rem;
    text-align: center;
  }
}
@media (max-height: 700px) {
  .about-title {
    max-height: 30vh;
  }
}
